// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.option-data {
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 100;
}

.option-data-input {
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 50;
}

.container-option {
  position: relative;
}

.indicator-option {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 20px; /* высота индикатора */
  background-color: #ccc;
}

select{
    width: 10%;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding-left: 5px;
    padding: 5px;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    color: #555;
    background-color: rgb(255, 255, 255);
    background-image: none;
    border: 2px solid rgb(65, 154, 35);
}
select>option{
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    color: #555;
    background-color: rgb(247, 247, 247);
    background-image: none;
    font-size: 18px;
    height: 50px;
    padding: 15px;
    border: 1px solid rgb(41, 18, 18);
}`, "",{"version":3,"sources":["webpack://./src/pages/Mobile/mobile.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,WAAW;EACX,UAAU;EACV,YAAY;AACd;;AAEA;EACE,eAAe;EACf,WAAW;EACX,UAAU;EACV,WAAW;AACb;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,YAAY,EAAE,sBAAsB;EACpC,sBAAsB;AACxB;;AAEA;IACI,UAAU;IACV,sBAAsB;IACtB,kBAAkB;IAClB,iBAAiB;IACjB,YAAY;IACZ,eAAe;IACf,oCAAoC;IACpC,WAAW;IACX,oCAAoC;IACpC,sBAAsB;IACtB,kCAAkC;AACtC;AACA;IACI,eAAe;IACf,oCAAoC;IACpC,WAAW;IACX,oCAAoC;IACpC,sBAAsB;IACtB,eAAe;IACf,YAAY;IACZ,aAAa;IACb,iCAAiC;AACrC","sourcesContent":[".option-data {\n  position: fixed;\n  bottom: 0px;\n  right: 0px;\n  z-index: 100;\n}\n\n.option-data-input {\n  position: fixed;\n  bottom: 0px;\n  right: 0px;\n  z-index: 50;\n}\n\n.container-option {\n  position: relative;\n}\n\n.indicator-option {\n  position: absolute;\n  bottom: 0;\n  width: 100%;\n  height: 20px; /* высота индикатора */\n  background-color: #ccc;\n}\n\nselect{\n    width: 10%;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n    padding-left: 5px;\n    padding: 5px;\n    font-size: 16px;\n    font-family: 'Open Sans', sans-serif;\n    color: #555;\n    background-color: rgb(255, 255, 255);\n    background-image: none;\n    border: 2px solid rgb(65, 154, 35);\n}\nselect>option{\n    font-size: 16px;\n    font-family: 'Open Sans', sans-serif;\n    color: #555;\n    background-color: rgb(247, 247, 247);\n    background-image: none;\n    font-size: 18px;\n    height: 50px;\n    padding: 15px;\n    border: 1px solid rgb(41, 18, 18);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
