/**
 * Get profile
 */

export const GET_PROFILE = "GET_PROFILE"
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS"
export const GET_PROFILE_FAIL = "GET_PROFILE_FAIL"

/**
 * Edit profile
 */
export const UPDATE_PROFILE = "UPDATE_PROFILE"
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS"
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL"