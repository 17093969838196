// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordion-custom-body div {
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.text-mobile {
  color: forestgreen;
}`, "",{"version":3,"sources":["webpack://./src/components/Accordion/Accordion.scss"],"names":[],"mappings":"AACE;EAEE,sBAAA;EACA,uBAAA;EACA,WAAA;AADJ;;AAKA;EACE,kBAAA;AAFF","sourcesContent":[".accordion-custom-body {\n  div {\n    //display: flex;\n    flex-direction: column;\n    justify-content: center;\n    width: 100%;\n  }\n}\n\n.text-mobile {\n  color: forestgreen;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
