// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-status-account-white {
  /*background-color: #2A3042;*/
  background-color: #00425A !important;
}

.bg-status-account-black {
  background-color: #000000 !important;
}

.bg-status-account-btn {
  background: #391a60 !important;
  color: white !important;;
}

.text-status-account-black {
  background: #391a60 !important;
}

.bg-status-account-white-logo {
  /*background-color: #2A3042;*/
  background: #00425A;
}

.bg-status-account-black-logo {
  background: #000000;
}

/*.bg-status-account-black button i, span {*/
/*  color: #FFF1DC;*/
/*}*/

/*.bg-status-account-white i, span {*/
/*  color: white;*/
/*}*/
`, "",{"version":3,"sources":["webpack://./src/status_account.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,oCAAoC;AACtC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,8BAA8B;EAC9B,uBAAuB;AACzB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,6BAA6B;EAC7B,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA,4CAA4C;AAC5C,oBAAoB;AACpB,IAAI;;AAEJ,qCAAqC;AACrC,kBAAkB;AAClB,IAAI","sourcesContent":[".bg-status-account-white {\n  /*background-color: #2A3042;*/\n  background-color: #00425A !important;\n}\n\n.bg-status-account-black {\n  background-color: #000000 !important;\n}\n\n.bg-status-account-btn {\n  background: #391a60 !important;\n  color: white !important;;\n}\n\n.text-status-account-black {\n  background: #391a60 !important;\n}\n\n.bg-status-account-white-logo {\n  /*background-color: #2A3042;*/\n  background: #00425A;\n}\n\n.bg-status-account-black-logo {\n  background: #000000;\n}\n\n/*.bg-status-account-black button i, span {*/\n/*  color: #FFF1DC;*/\n/*}*/\n\n/*.bg-status-account-white i, span {*/\n/*  color: white;*/\n/*}*/\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
