/**
 * add customer
 */
export const ADD_CUSTOMER_DATA = "ADD_CUSTOMER_DATA";
export const ADD_CUSTOMER_DATA_SUCCESS = "ADD_CUSTOMER_DATA_SUCCESS";
export const ADD_CUSTOMER_DATA_FAIL = "ADD_CUSTOMER_DATA_FAIL";

/**
 * get customers
 */
export const GET_CUSTOMER_DATA = "GET_CUSTOMER_DATA"
export const GET_CUSTOMER_DATA_SUCCESS = "GET_CUSTOMER_DATA_SUCCESS"
export const GET_CUSTOMER_DATA_FAIL = "GET_CUSTOMER_DATA_FAIL"
export const GET_CUSTOMER_DETAIL = "GET_CUSTOMER_DETAIL"
export const GET_CUSTOMER_DETAIL_SUCCESS = "GET_CUSTOMER_DETAIL_SUCCESS"
export const GET_CUSTOMER_DETAIL_FAIL = "GET_CUSTOMER_DETAIL_FAIL"

/**
 * update customer
 */
export const UPDATE_CUSTOMER_DATA = "UPDATE_CUSTOMER_DATA"
export const UPDATE_CUSTOMER_DATA_SUCCESS = "UPDATE_CUSTOMER_DATA_SUCCESS"
export const UPDATE_CUSTOMER_DATA_FAIL = "UPDATE_CUSTOMER_DATA_FAIL"

/**
 * delete customer
 */
export const DELETE_CUSTOMER_DATA = "DELETE_CUSTOMER_DATA"
export const DELETE_CUSTOMER_DATA_SUCCESS = "DELETE_CUSTOMER_DATA_SUCCESS"
export const DELETE_CUSTOMER_DATA_FAIL = "DELETE_CUSTOMER_DATA_FAIL"