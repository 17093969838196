/**
 * get invoices all
 */
export const GET_INVOICES = "GET_INVOICES"
export const GET_INVOICES_SUCCESS = "GET_INVOICES_SUCCESS"
export const GET_INVOICES_FAIL = "GET_INVOICES_FAIL"
/**
 * get invoices detail
 */
export const GET_INVOICE_DETAIL = "GET_INVOICE_DETAIL"
export const GET_INVOICE_DETAIL_SUCCESS = "GET_INVOICE_DETAIL_SUCCESS"
export const GET_INVOICE_DETAIL_FAIL = "GET_INVOICE_DETAIL_FAIL"

/**
 * get invoices id customer
 */
export const GET_INVOICE_CUSTOMER = "GET_INVOICE_CUSTOMER"
export const GET_INVOICE_CUSTOMER_SUCCESS = "GET_INVOICE_CUSTOMER_SUCCESS"
export const GET_INVOICE_CUSTOMER_FAIL = "GET_INVOICE_CUSTOMER_FAIL"

/**
 * export invoice
 */
export const INVOICE_EXPORT = "INVOICE_EXPORT"
export const INVOICE_EXPORT_SUCCESS = "INVOICE_EXPORT_SUCCESS"
export const INVOICE_EXPORT_FAIL = "INVOICE_EXPORT_FAIL"

/**
 * export invoice list
 */
export const INVOICE_EXPORT_LIST = "INVOICE_EXPORT_LIST"
export const INVOICE_EXPORT_LIST_SUCCESS = "INVOICE_EXPORT_LIST_SUCCESS"
export const INVOICE_EXPORT_LIST_FAIL = "INVOICE_EXPORT_LIST_FAIL"

/**
 * update status
 */
export const UPDATE_STATUS = "UPDATE_STATUS "
export const UPDATE_STATUS_SUCCESS = "UPDATE_STATUS_SUCCESS"
export const UPDATE_STATUS_FAIL = "UPDATE_STATUS_FAIL"

/**
 * export invoice csv
 */
export const INVOICE_EXPORT_CSV = "INVOICE_EXPORT_CSV"
export const INVOICE_EXPORT_CSV_SUCCESS = "INVOICE_EXPORT_CSV_SUCCESS"
export const INVOICE_EXPORT_CSV_FAIL = "INVOICE_EXPORT_CSV_FAIL"

/**
 * get invoices my day
 */
export const GET_MY_DAY = "GET_MY_DAY"
export const GET_MY_DAY_SUCCESS = "GET_MY_DAY_SUCCESS"
export const GET_MY_DAY_FAIL = "GET_MY_DAY_FAIL"

/**
 * invoices my day
 */
export const INVOICE_MY_DAY = "INVOICE_MY_DAY"
export const INVOICE_MY_DAY_SUCCESS = "INVOICE_MY_DAY_SUCCESS"
export const INVOICE_MY_DAY_FAIL = "INVOICE_MY_DAY_FAIL"

/**
 * send invoice
 */
export const INVOICE_SEND = "INVOICE_SEND"
export const INVOICE_SEND_SUCCESS = "INVOICE_SEND_SUCCESS"
export const INVOICE_SEND_FAIL = "INVOICE_SEND_FAIL"

/**
 * send invoice list
 */
export const INVOICE_SEND_LIST = "INVOICE_SEND_LIST"
export const INVOICE_SEND_LIST_SUCCESS = "INVOICE_SEND_LIST_SUCCESS"
export const INVOICE_SEND_LIST_FAIL = "INVOICE_SEND_LIST_FAIL"